import React from 'react'
import Portfolios from '../components/portfolios'
import PortfoliosJSON from "../content/portfolios.json"
import MaritimeChart from "../components/maritime-transportation-chart"

export default function transportation() {
    const content = PortfoliosJSON.portfolios[3]
    return (
        <Portfolios content={content} subcontent={""} chart={<MaritimeChart/>} />
    )
}
